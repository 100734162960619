"use strict";

(function () {
  Turbolinks.start();

  var ready = function ready() {
    console.log("🦄 TurboLinks Ready -> " + window.location.href + " width: " + $(window).width());
    var pageWidth = $(window).width(); // We reset the scroll to 0 at every page load

    $('html, body').scrollTop(0); // Animations au scroll

    $(window).scroll(function () {
      var scroll = $(window).scrollTop(); // Effet du header au scroll

      if (screen.width >= 1024) {
        if (scroll >= 280) {
          $('body').addClass('on-scroll');
        } else {
          $('body').removeClass('on-scroll');
        }
      }
    });

    if (pageWidth >= 481) {
      setTimeout(function () {
        setAnimationList();
      }, 100);
    }

    $('a').bkoScroll();
    $("body").bkoCookies({
      bkoBgColor: '#000000'
    });
    $(".menu-mobile").bkoMenu();
    $(".contact__form").bkoContact('fca9f9581d7ebb4c0834ef4b0079bb9fc059d0e2', 'terra@terra-plants.com');
    lazywatcher(); // mentions legales

    $('.legales__button').on('click', function () {
      $('.legales').toggleClass('show');
    });
    $('.legales__close').on('click', function () {
      $('.legales').toggleClass('show');
    }); // plantes tabs

    $('.plante__tab--1, .plante__tab--2, .plante__tab--3, .plante__tab--4, .plante__tab--5, .plante__tab--6, .plante__tab--7').on('click', function () {
      $('.plante__tab--1, .plante__tab--2, .plante__tab--3, .plante__tab--4, .plante__tab--5, .plante__tab--5, .plante__tab--6, .plante__tab--7').removeClass('highlightTab');
      $('.plante__section').removeClass('showTab');
    });
    $('.plante__tab--1').on('click', function () {
      $('.plante__tab--1').addClass('highlightTab');
      $('.plante--1').addClass('showTab');
    });
    $('.plante__tab--2').on('click', function () {
      $('.plante__tab--2').addClass('highlightTab');
      $('.plante--2').addClass('showTab');
    });
    $('.plante__tab--3').on('click', function () {
      $('.plante__tab--3').addClass('highlightTab');
      $('.plante--3').addClass('showTab');
    });
    $('.plante__tab--4').on('click', function () {
      $('.plante__tab--4').addClass('highlightTab');
      $('.plante--4').addClass('showTab');
    });
    $('.plante__tab--5').on('click', function () {
      $('.plante__tab--5').addClass('highlightTab');
      $('.plante--5').addClass('showTab');
    });
    $('.plante__tab--6').on('click', function () {
      $('.plante__tab--6').addClass('highlightTab');
      $('.plante--6').addClass('showTab');
    });
    $('.plante__tab--7').on('click', function () {
      $('.plante__tab--7').addClass('highlightTab');
      $('.plante--7').addClass('showTab');
    });
  };

  var cache = function cache() {
    console.log("🦄 TurboLinks CacheLoad");
    resetAnimationList();
  };

  document.addEventListener("turbolinks:before-cache", cache);
  document.addEventListener("turbolinks:load", ready);
  $(window).on('scroll', function () {
    var fromTop = $(window).scrollTop();

    if ($(window).width() >= 481) {
      setAnimationList();
    }

    $('body').toggleClass("down", fromTop > 150);
  });
  /**
   * Uncoment to unable google analitycs & update gulpfile.js
   */
  // gaInit( 'G-RLQ0SVBGHY' );
})(jQuery);