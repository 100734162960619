"use strict";

$.fn.bkoContact = function (tokenApi, emailTo) {
  var $self = $(this);

  if ($self.isBound("submit") === false) {
    $self.on("submit", function (e) {
      e.preventDefault();
      var $form = $(this);
      var data = $(this).serializeArray();
      var message = '';
      var subject = 'Nouveau contact - Site internet';

      for (var i = 0; i <= data.length - 1; i++) {
        if (data[i]['name'] === "Nom" || data[i]['name'] === "Prenom" || data[i]['name'] === "Objet" || data[i]['name'] === "Ville" || data[i]['name'] === "Postcode" || data[i]['name'] === "Demande") {
          message += "<b>" + data[i]['name'] + ": </b>" + data[i]['value'] + "<br />";
        } else if (data[i]['name'] === "Demande") {
          message += "<br /> <br />" + nl2br(data[i]['value']) + "<hr />";
        } else if (data[i]['name'] === "subject") {
          subject += data[i]['value'];
        }
      }

      jQuery.ajax({
        type: 'POST',
        url: 'https://www.bkotest.com/staticmailer/staticmailer.php',
        data: {
          'token': getAPITkn(tokenApi),
          'mail': emailTo,
          'subject': subject,
          'message': message
        },
        success: function success(resp) {
          console.log(resp);
          $('#contact__error').addClass("alert__success");

          if (document.documentElement.lang === "fr") {
            document.getElementById("contact__error").innerHTML = "Votre message a bien été envoyé.";
          } else {
            document.getElementById("contact__error").innerHTML = "Your message has been sent successfully";
          }

          eraseForm(".contact__form");
        },
        error: function error(resp) {
          console.log("error bkoFormidableSubmitForm", resp);
          $('#contact__error').addClass("alert__error");

          if (document.documentElement.lang === "fr") {
            document.getElementById("contact__error").innerHTML = "Une erreur s'est produite lors de l'envoi de votre message.";
          } else {
            document.getElementById("contact__error").innerHTML = "An error occurred while sending your message.";
          }
        }
      });
    });
  }
};

function getAPITkn(tokenApi) {
  var kley = tokenApi;
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  return MD5(kley + MD5(yyyy + '-' + mm + '-' + dd));
}